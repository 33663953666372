/**
 * function converts a given string to lowercase and replaces spaces with underscores.
 * @param {string} inputString - The string to be formatted.
 * @returns {string} - The formatted string.
 */
function convertSpacesToUnderscores(inputString) {
  console.log(inputString, inputString.toLowerCase().replace(/ /g, '_'))
  return inputString.toLowerCase().replace(/ /g, '_')
}

export default convertSpacesToUnderscores
