import { Box, Grid, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { rem } from 'src/common/utils/css'
import CardQuickLink from 'src/components/park-overview/CardQuickLink'
import theme from 'src/styles/theme'
import { makeStyles } from 'tss-react/mui'
import convertSpacesToUnderscores from 'src/common/utils/js/convertSpacesToUnderScore'

const colorType = {
  CYPRESS_GREEN: 'cypress_green',
  BAKER_BEACH: 'baker_beach',
}

const useStyles = makeStyles()((_theme, props) => ({
  container: {
    width: '100%',
    padding: '40px 24px',
    backgroundColor:
      props.color === colorType.CYPRESS_GREEN
        ? theme.palette.secondary.dark
        : theme.palette.presidio.color.LIGHT_BACKGROUND,
    [theme.breakpoints.up('md')]: {
      padding: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '64px 40px',
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '64px 157px',
    },
  },
  title: {
    color:
      props.color === colorType.CYPRESS_GREEN
        ? theme.palette.presidio.color.NEAR_WHITE
        : theme.palette.primary.dark,
  },
  description: {
    ...theme.typography.body.default,
    color:
      props.color === colorType.CYPRESS_GREEN
        ? theme.palette.presidio.color.NEAR_WHITE
        : theme.palette.primary.dark,
  },
  containerText: {
    width: '100%',
    flex: 1,
    display: 'flex',
    gap: rem(8),
    flexDirection: 'column',
  },
  gridItem: {
    '& a:first-of-type': {
      marginTop: '24px',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      '& a:first-of-type': {
        marginTop: 'unset',
      },
    },
  },
  gridItem2: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
}))

function ParkOverview(props) {
  const { data } = props
  if (!data) return null

  const { park_overview, section_id } = data
  if (!park_overview) return null

  const { title, description, background_color, links } = park_overview
  const formattedBackgroundColor = convertSpacesToUnderscores(background_color)
  if (!links || !Array.isArray(links) || links.length === 0) return null

  const { classes } = useStyles({
    color: formattedBackgroundColor,
  })

  return (
    <Box
      className={`${classes.container} module`}
      id={section_id}
      data-id="section"
    >
      <Grid container spacing={{ xs: 2, lg: 5, xl: 8 }}>
        <Grid item xs={12} sm={12} md={12} lg={6} className={classes.gridItem}>
          <Stack spacing={2}>
            <Box className={classes.containerText}>
              <Typography
                variant="h2"
                className={classes.title}
                data-testid="quick-link-title"
              >
                {title}
              </Typography>
              {description && (
                <Typography
                  className={classes.description}
                  data-testid="quick-link-description"
                >
                  {description}
                </Typography>
              )}
            </Box>
            {links.slice(0, Math.floor(links.length / 2)).map((item, index) => (
              <CardQuickLink
                key={index}
                data={{ ...item, background_color: formattedBackgroundColor }}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} className={classes.gridItem2}>
          <Stack spacing={2}>
            {links.slice(Math.floor(links.length / 2)).map((item, index) => (
              <CardQuickLink
                key={index}
                data={{ ...item, background_color: formattedBackgroundColor }}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ParkOverview

ParkOverview.propTypes = {
  data: PropTypes.shape({
    quick_links: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      background_color: PropTypes.oneOf([
        colorType.BAKER_BEACH,
        colorType.CYPRESS_GREEN,
      ]),
      links: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string.isRequired,
            target: PropTypes.string,
          }).isRequired,
          image: PropTypes.object.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }),
}
